import React, { useEffect, useRef } from "react";
import "../styles/contact.css";
import { Container } from "react-bootstrap";
import Aos from "aos";
import emailjs from "@emailjs/browser";
function Contact() {
  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  });

  const form = useRef();
  const reachus = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_a9olqgx",
        "template_z48vfnq",
        form.current,
        "NqWep0rysAMC8WPiB"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("send Successfully")
          window.location.assign("");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const form1 = useRef();
  const TeacherCarrier = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_a9olqgx",
        "template_9i8zvuw",
        form.current,
        "NqWep0rysAMC8WPiB"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Send Successfully...!")
          window.location.assign("/");
        },
        (error) => {
          console.log(error.text);
          alert("Error")
        }
      );
  };

  return (
    <div className="contact-mainpage">
      {/* <div className='link-service'>
                <a href="/">Home</a>/
                <a href="/gallery">Gallery</a>
                </div> */}

      <div>
        <img
          src="/Assets/contactus-banner3.jpg"
          alt="home img"
          className="homeheading"
        />
        <div>
          <p className="abtus-heading">CONTACT US</p>
        </div>
      </div>

      <section>
        {/* <img src="../Assets/contactus1.png" alt="" className='setion-img' /> */}

        <Container>
          <div class="row mt-3 mb-3">
            <div class="col-md-4 mt-3">
              <div
                class="contact-bx"
                data-aos="flip-left"
                data-aos-duration="3000"
              >
                <img src="../Assets/locations.png" alt="Location" />
                <h3 class="sect-title-3">Address</h3>
                <p className="text-justify">
                  Eastern Enclave, Near Decathlon, MLA Layout, Kalena Agrahara,
                  BG Road, Bengaluru-76
                </p>
              </div>
            </div>

            <div
              class="col-md-4 mt-3"
              data-aos="flip-left"
              data-aos-duration="3000"
            >
              <div class="contact-bx">
                <img src="../Assets/contacticon.png" alt="Location" />
                <h3 class="sect-title-3">Contact Number</h3>
                <p className="text-left ">+91- 8217261256</p>
                <p className="text-left mobile2">+91- 9742036366</p>
                {/* <p  className='text-center'>+91- 9902268073</p>
                                <p  className='text-center'>+91- 8660202361</p> */}
              </div>
            </div>

            <div class="col-md-4 mt-3">
              <div
                class="contact-bx"
                data-aos="flip-left"
                data-aos-duration="3000"
              >
                <img src="../Assets/email.png" alt="Location" />
                <h3 class="sect-title-3">Email</h3>
                <p>info@aedinlearners.in</p>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <Container>
        <div className="row" id="contct-img">
          <div className="col-md-5 col-12">
            <div
              className="contact-form"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="2000"
            >
              <div className="form-bg">
                <h1 style={{ color: "white" }}>Reach-Us</h1>

                <form className="row" ref={form} onSubmit={reachus}>
                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name..."
                        name="user_name"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email..."
                        name="user_email"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number..."
                        name="user_phone"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student grade..."
                        name="user_grade"
                        required
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subjects..."
                        name="user_subject"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div>
                      <button
                        type="submit"
                        class="upgrade  btn btn-primary btn block mt-3"
                      >
                        Upgrade Your Academic Results
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-md-7 col-12 mt-3 mb-3">
            <div
              className="form-img"
              data-aos="zoom-in"
              data-aos-duration="2000"
            >
              <img
                src="../Assets/contact1.jpg"
                alt="Contact"
                className="img-fluig"
              />
            </div>
          </div>
        </div>
      </Container>

      <section className="contact-form">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <div className="opening">
                <h5>
                  Fill out the form to join our team of dedicated educators, and
                  take the next step in your professional journey.{" "}
                </h5>
                <div className="currentopening">
                  <h4 style={{ color: "white" }}> CURRENT OPENINGS</h4>
                  <h5>
                    Require trained teachers for "Mathematics" for secondary and
                    senior secondary classes.
                  </h5>
                </div>
                <div className="email">
                  <h4 style={{ color: "white" }}>
                    {" "}
                    please send your resume to
                  </h4>
                  <a href="">info@aedinlearners.in</a>
                </div>
              </div>
            </div>

            <div className="col-md-7">
              <form 
               ref={form1} onSubmit={TeacherCarrier}
                className="contact-form"
                data-aos="fade-down"
                data-aos-easing="linear"
                data-aos-duration="1500"
              >
                <div className="form-bg1">
                  <h1 className="career">
                    Advance Your Teaching Career with Us
                  </h1>

                  <div className="row">
                    <div className="col-12">
                      <div className="single-form">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Full Name..."
                          name="teacher_name"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-form">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Email Address..."
                          name="teacher_email"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-form">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Phone Number..."
                          name="teacher_phone"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-form">
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="City..."
                          name="teacher_city"
                          required
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="single-form">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Highest Degree..."
                          name="teacher_highestdegree"
                          required
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Total Year of Teaching Experience..."
                        name="teacher_experience"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subjects Taught..."
                        name="teacher_taught"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Grade Levels Taught..."
                        name="teacher_gardelavel"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-form">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Message..."
                        name="teacher_message"
                        required
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="single-form">
                      <button type="submit" className="btns">
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
