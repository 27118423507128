import React, { useState, useEffect } from 'react'
import "../styles/Home.css"
import { Container, Card, Row, Col } from "react-bootstrap";
import { IoPeopleSharp } from "react-icons/io5";
import { FaSquareWhatsapp } from "react-icons/fa6";
import Aos from "aos";
import Form from 'react-bootstrap/Form';
import OwlCarousel from 'react-owl-carousel'
import { Link } from 'react-router-dom';
import Multiselect from 'multiselect-react-dropdown';
const Home = () => {

  const [options1] = useState([
    { name: 'Maths', id: 'maths' },
    { name: 'Science', id: 'science' },
    { name: 'Physics', id: 'physics' },
    { name: 'Chemistry', id: 'chemistry' },
    { name: 'Biology', id: 'biology' },
  ]);

  const onSelect = (selectedList, selectedItem) => {
    console.log(selectedList, selectedItem);
  };

  const onRemove = (selectedList, removedItem) => {
    console.log(selectedList, removedItem);
  };

  const options = {

    margin: 20,
    responsiveClass: true,
    nav: false,
    dots: true,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {

      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 2,
      },
      1000: {
        items: 3,
      }

    },

  };






  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    class: '',
    board: '',
    programs: '',
    course: '',
    selectedStates: [],
  });

  const handleChange = (e) => {
    const { name, value, type, selectedOptions } = e.target;
    if (type === 'select-multiple') {
      const selectedValues = Array.from(selectedOptions).map(option => option.value);
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: selectedValues,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Perform your form submission logic here, e.g., sending data to a server
    console.log('Form Data Submitted:', formData);

    alert("The form data is submitted");
  };


  useEffect(() => {
    Aos.init();
    window.scroll(0, 0);
  }, []);

  // form


  // const [formData, setFormData] = useState({
  //   name: '',
  //   phone: '',
  //   email: '',
  //   class: '',
  //   board: '',
  //   programs: '',
  //   course: '',
  //   selectedStates: [],
  // });

  // const handleChange = (e) => {
  //   const { name, value, type, selectedOptions } = e.target;
  //   if (type === 'select-multiple') {
  //     const selectedValues = Array.from(selectedOptions).map(option => option.value);
  //     setFormData({
  //       ...formData,
  //       selectedStates: selectedValues,
  //     });
  //   } else {
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   }
  // };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   console.log('Form Data Submitted:', formData);
  // };



  // states
  //   const [selectedStates, setSelectedStates] = useState([]);

  // const handleChange = (event) => {
  //   const options = event.target.options;
  //   const selectedValues = [];
  //   for (let i = 0; i < options.length; i++) {
  //     if (options[i].selected) {
  //       selectedValues.push(options[i].value);
  //     }
  //   }
  //   setSelectedStates(selectedValues);
  // };



  // useEffect(() => {
  //   Aos.init();
  //   window.scroll(0, 0)
  // })



  // Email Integration

  return (

    <div className='home-mainpage'>

      {/* <div >
            <img src="/Assets/homeheading.jpg" alt="home img" className='homeheading' />
            <div >
            <p className='home-hdng'>Home</p>
            </div>
        </div> */}




      <div className='position-relative'>

        <img src="/Assets/brd6.jpg" alt="background-image" className='background-image'></img>



        <Container className='text-form-container'>
          <div className="row mt-3 mb-3">

            <div className="col-md-6 mt-5 " data-aos="zoom-in" data-aos-duration="3000">
              <h1>Welcome to AEDIN, The Learner’s Hub.</h1>
              <h3 className='text-secondary'> At Aedin: </h3>
              <div>The Learners' Hub, we are committed to nurturing the next generation of thinkers, innovators, and problem-solvers. Join us in shaping a future where learning is empowering, enriching, and enduring. Discover the difference of concept-based education with Aedin today.</div>
              <button className='know-more-buttonn'>
                <a href='/aboutus' className='know-more'>Know More</a>
              </button>
            </div>

            <div className="col-md-6" data-aos="zoom-in" data-aos-duration="3000">
              <form className="form-group form-container p-3 mt-5 ml-5" onSubmit={handleSubmit}>
                <h4 className='inform mb-2 ml-2'>Information Request Form</h4>

                <div className="form-row mb-3 d-flex gap-1">
                  <div className="col-md-6">



                    <label htmlFor="name" className='common-form-labels'>Name</label>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      id="name"
                      name="name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="phone" className='common-form-labels'>Mobile</label>
                    <input
                      type="tel"
                      placeholder="Enter your Phone number"
                      id="phone"
                      name="phone"
                      className="form-control"
                      value={formData.phone}
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="form-row mb-3 d-flex gap-1">
                  <div className="col-md-6">
                    <label htmlFor="email" className='common-form-labels'>Email</label>
                    <input
                      type="email"
                      placeholder="Enter your email"
                      id="email"
                      name="email"
                      className="form-control"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div >
                  <div className="col-md-6">



                    <label htmlFor="class" className='common-form-labels'>Class</label>


                    <select
                      name="class"
                      id="class"
                      className="form-control class-form-drag"
                      value={formData.class}
                      onChange={handleChange}

                    >
                      <option className='removesel' value="" >Select</option>
                      <option value="sixth">6th</option>
                      <option value="seventh">7th</option>
                      <option value="eighth">8th</option>
                      <option value="ninth">9th</option>
                      <option value="tenth">10th</option>
                      <option value="tenth">11th</option>
                      <option value="tenth">12th</option>
                    </select>



                  </div>
                </div>

                <div className="form-row mb-3 d-flex gap-1">
                  <div className="col-md-6">
                    <label htmlFor="board" className='common-form-labels'>Board</label>
                    <select
                      name="board"
                      id="board"
                      className="form-control"

                      value={formData.board}
                      title='press ctrl for multple select'
                      onChange={handleChange}
                    >
                      <option className='removesel' value="">Select</option>
                      <option value="cbse">CBSE</option>
                      <option value="ICSE">ICSE</option>
                      <option value="IGSCE">IGSCE</option>
                      <option value="IB">IB</option>
                      <option value="STATE">STATE</option>
                    </select>
                  </div>
                  <div className="col-md-6">
                    {/* <label htmlFor="programs">Programs</label>
                    <select
                      name="programs"
                     
                      id="programs"
                      className="form-control"
                      value={formData.programs}
                      onChange={handleChange}
                    >

                    <option className='removesel' value="">Select </option>
                      <option value="maths">Maths</option>
                      <option value="science">Science</option>
                      <option value="physics">Physics</option>
                      <option value="chemistry">Chemistry</option>
                      <option value="biology">Biology</option>

                    </select> */}

                    <label htmlFor="programs" className='common-form-labels'>Programs</label>

                    <Multiselect className='program-input'
                      options={options1} // Options to display in the dropdown
                      selectedValues={[]} // Preselected value to persist in dropdown
                      onSelect={onSelect} // Function will trigger on select event
                      onRemove={onRemove} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                    />

                  </div>
                </div>

                <div className="form-row mb-3 d-flex gap-1">


                  {/* <label htmlFor="course">Course</label>
                    <select
                      name="course"
                      id="course"
                     
                      className="form-control"
                      value={formData.course}
                      onChange={handleChange}
                    >
                       <option className='removesel' value="">Select  </option>
                      <option value="offline" type="checkbox">Offline</option>
                      <option value="online" type="checkbox">Online</option>
                    </select> */}


                  {/* <label for="course">Course</label>
                    <input type="checkbox" id="sports" name="sports">Select</input>
                    <input type="checkbox" id="sports" name="sports1">Online</input>
                    <input type="checkbox" id="sports" name="sports2">Offline</input> */}

                  {/* <div className="col-md-6">
                  <label htmlFor="sports">Course</label>
                  <input type="checkbox" id="sports" name="sports" />Select
                  <input type="checkbox" id="sports1" name="sports1" />Online
                  <input type="checkbox" id="sports2" name="sports2" />Offline
                </div> */}




                  <div className="col-md-6">
                    <label htmlFor="states" className='common-form-labels'>State</label>
                    <select
                      id="states"
                      name="selectedStates"
                      className="form-control scrolling-select"
                      // multiple
                      value={formData.selectedStates}
                      onChange={handleChange}
                    >
                      <option className='removesel' value="">Select  </option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Goa">Goa</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Assam">Assam</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="West Bengal">West Bengal</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    {/* <div>
                      Selected States: {formData.selectedStates.join(', ')}
                    </div> */}
                  </div>
                </div>

                <button type="submit" className="submit-buttonn mt-3 ml-3">Submit</button>
              </form>
            </div>
          </div>
        </Container>

      </div>



      <Container>

        <h1 className="center-text mt-5 mb-3">We are conducting</h1>

        <div className='d-flex justify-content-center mt-3 '>
          <div className='row' data-aos="zoom-in" data-aos-duration="3000">

            <div className='col-md-6 d-flex justify-content-center align-items-center mb-3 '>
              <div className="card h-100 " >

                <div className="card-body d-flex flex-column justify-content-center align-items-center text-center">
                  <p className='fs-3 classes'>Online and Offline Classes</p>
                  <p className="card-text">We offer both online and offline classes to our students. Whether you prefer the convenience of learning from home or the structured environment of a classroom, Aedin has the right solution for you.</p>

                </div>
              </div>
            </div>

            <div className='col-md-6 d-flex justify-content-center align-items-center mb-3 '>
              <div className="card h-100 " >

                <div className="card-body d-flex flex-column justify-content-center align-items-center text-center">
                  <p className='fs-3 classes'>Academic and Boards</p>
                  <p className="card-text">We are dedicated to providing high-quality educational support for students from 6th to 10th grade across various educational boards, including State, ICSE, CBSE, and IB.</p>

                </div>
              </div>
            </div>


          </div>
        </div>
      </Container>


      <Container>

        <h4 className="center-text mt-3 ">Find a Course</h4>
        <h2 className="center-text text-secondary">Academic Courses</h2>

        <div className='d-flex justify-content-center'>
          <div className='row' data-aos="zoom-in" data-aos-duration="3000">

            <div className='col-md-6 d-flex justify-content-center align-items-center mb-3'>
              <div className="card h-75" >
                <img src="../Assets/math.avif" alt="class1" className='h-50 img-fluid mx-auto d-block' />
                <div className="card-body d-flex flex-column justify-content-center align-items-center text-center">
                  <p className="card-text">Covering algebra, geometry, calculus, and more, our math curriculum is designed to build a strong foundation and advanced problem-solving skills.</p>
                  <a href="/academic/Maths" className="btn  mt-auto">Read More</a>
                </div>
              </div>
            </div>

            <div className='col-md-6 d-flex justify-content-center align-items-center mb-3'>
              <div className="card h-75" >
                <img src="../Assets/science.jpg" alt="class1" className='h-50 img-fluid mx-auto d-block' />
                <div className="card-body d-flex flex-column justify-content-center align-items-center text-center">
                  <p className="card-text">From physics and chemistry to biology, we explore the wonders of science through hands-on experiments and theoretical exploration.</p>
                  <a href="/academic/Science" className="btn  mt-auto">Read More</a>
                </div>
              </div>
            </div>


          </div>
        </div>
      </Container>

      <Container>
        <div className='col-md-12'>

          <video controls className="video">
            <source src="../Assets/aedin-video.mp4" type="video/mp4" />
          </video>
        </div>
      </Container>


      <Container>
        <div className='whatsapp-content-border mt-3' data-aos="zoom-in" data-aos-duration="3000" >

          <div className='row  mb-3 ' data-aos="zoom-in" data-aos-duration="3000">

            <div className='col-md-12 for-free-demo' data-aos="zoom-in" data-aos-duration="3000">

              <div>
                <img src="../Assets/for-freedemo.jpg" alt="" className='for-freedemo' />
                <p className='free-demo fs-1' data-aos="zoom-in" data-aos-duration="3000"><b>For Free Demo Class</b></p>
                <FaSquareWhatsapp className='whtsapp-icon' data-aos="zoom-in" data-aos-duration="3000" />
                {/* <p> <FaSquareWhatsapp/></p>
                <div className='free-demo-content d-flex '>
                
                <div>
                  <b>+91- 8217261256</b> 
                  <b>+91- 9742036366</b>
                </div>

                <div>
                <b>+91- 9902268073</b>
                  <b>+91- 8660202361</b>
                </div>
               </div> */}



                <div className='mobile-numbers mt-3 mr-5 p-2  d-flex gap-5 align-items-center' data-aos="zoom-in" data-aos-duration="3000">
                  <div>
                    <div >
                      <b>+91- 8217261256</b>
                    </div>

                    <div>
                      <b>+91- 9742036366</b>
                    </div>

                  </div>

                  <div>
                    <div>
                      <b>+91- 9902268073</b>
                    </div>

                    <div>
                      <b>+91- 8660202361</b>
                    </div>

                  </div>

                </div>
              </div>





              {/* <div>
                <p className='mt-2 textt'><b><u>FOR FREE DEMO CLASS</u></b></p>
                <p className='textt'><b>+91 8217261256 /+91 9742036366</b></p>
            </div> */}



            </div>
          </div>

        </div>

      </Container>


      <Container>
        <h1 className="center-text mt-5 mb-3">Why Aedin?</h1>
        <p>“Choosing the right coaching centre is crucial for your academic and professional success. At Aedin The Learner’s Hub, we are dedicated to providing an unparalleled learning experience that sets us apart. Here’s why Aedin is the perfect choice for your educational journey”</p>
        <Row className='mt-5 mb-3'>
          <Col md={6} data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 h-100 p-2'>
              <div className='iconsub-heading'>
                <img src="../Assets/class1.jpg" alt="class1" className='class' />
              </div>
              <Card className="text-center">
                <Card.Header className="custom-card-header">
                  <b> Expert Faculty</b>
                </Card.Header>
                <Card.Body>
                  <Card.Text>Our team of highly qualified and experienced educators is the backbone of Aedin. Each instructor brings a wealth of knowledge and a passion for teaching, ensuring that you receive the best education possible. They are committed to helping you understand complex concepts and succeed in your studies.</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={6} data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 h-100 p-2'>
              <div className='iconsub-heading'>
                <img src="../Assets/class2.avif" alt="class1" className='class' />
              </div>
              <Card className="text-center">
                <Card.Header className="custom-card-header">
                  <b>Personalized Learning</b>
                </Card.Header>
                <Card.Body>
                  <Card.Text>We recognize that every student is unique, and our personalized approach to teaching reflects this. We offer customized study plans and one-on-one mentoring to address your specific needs and learning style, helping you to achieve your academic goals effectively.</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={6} data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 h-100 p-2'>
              <div className='iconsub-heading'>
                <img src="../Assets/class3.jpg" alt="class1" className='class' />
              </div>
              <Card className="text-center">
                <Card.Header className="custom-card-header">
                  <b>Student’s Classroom Curriculum</b>
                </Card.Header>
                <Card.Body>
                  <Card.Text>We strengthen the foundational skills that help the students to be successful in their school curriculum.
                    Our class room programs provide opportunities to practice and apply the skills or concepts students learn in their school.
                    We create and provide supplemental materials based on students’ classroom curricula.
                    We address the misconceptions that students hold about concepts or terminology within their classroom curricula pre-emptively during our sessions.</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>

          <Col md={6} data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 h-100 p-2'>
              <div className='iconsub-heading'>
                <img src="../Assets/class4.jpg" alt="class1" className='class' />
              </div>
              <Card className="text-center">
                <Card.Header className="custom-card-header">
                  <b> Flexible Learning Options</b>
                </Card.Header>
                <Card.Body>
                  <Card.Text>We understand the demands of modern life, so we offer flexible learning options, including online classes and weekend batches. This flexibility ensures that you can balance your studies with other commitments and still receive a high-quality education.</Card.Text>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>



      <Container>
        <p className='fs-1 text-center'>Here's what students and parents are saying....</p>

        <div className='row mt-3 mb-5' data-aos="zoom-in" data-aos-duration="3000">

          <div className='col-md-12'>
            {/* <Container> */}

            <OwlCarousel className='owl-theme' loop margin={8} {...options}>

              <div >
                <Card >
                  {/* <Card.Header>
        <img src="../Assets/testimonial1.jpg" alt="" className='testimonial-img'/>
        </Card.Header> */}
                  <Card.Body>
                    {/* <div>
                      <img src="../Assets/testimonial1.PNG" alt="" className='testimonial-img1' />
                    </div> */}

                    <div>
                      <div className='name'><b>Teertha.R</b></div>
                      <div className='content-card'>
                        Class 10th
                      </div>
                      <div className='content-card'>
                        "Ma'am this class has been really helpful for me . All the teachers make sure you understood the topic and have no problem in explaining the same thing many times . Even the weekly test is really helpful as I make sure to study to study everyday . I have never understood science better . I have been getting good marks too after I joined Aedin."
                      </div>
                      {/* <Link to="/ourtean-1"><button>Know More</button></Link> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>



              <div>
                <Card  >
                  {/* <Card.Header>
        <img src="../Assets/testimonial2.jpg" alt="" className='testimonial-img'/>
        </Card.Header> */}
                  <Card.Body>
                    {/* <div>
                      <img src="../Assets/testimonial2.jpg" alt="" className='testimonial-img1' />
                     </div> */}

                    <div>
                      <div className='name'><b>Smyan Salian</b></div>
                      <div className='content-card'>
                        Class 9th
                      </div>
                      <div className='content-card'>
                        "I am having an excellent experience with AEDIN. The teachers here are knowledgeable, patient, and dedicated to helping students understand complex topics. Their personalized approach ensured that I received the attention and guidance I needed to succeed. The learning environment is supportive and encouraging, making it easy to ask questions and seek help. I highly recommend AEDIN for anyone looking to improve their academic performance."
                      </div>
                      {/* <Link to="/ourtean-2"><button>Know More</button></Link> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>


              <div >
                <Card>
                  {/* <Card.Header>
        <img src="" alt="" className='testimonial-img'/>
        </Card.Header> */}
                  <Card.Body>
                    {/* <div>
                      <img src="../Assets/testimonial3.webp" alt="" className='testimonial-img1' />
                    </div> */}

                    <div>
                      <div className='name'><b>Samrudh S B </b></div>
                      <div className='content-card'>
                        Class 8th
                      </div>
                      <div className='content-card'>
                        "Teachers at aedin know the academic requirements of students.I am very happy at aedin.My scores in science and maths have been improved.I started enjoying my studies due to aedin."
                      </div>
                      {/* <Link to="/ourtean-1"><button>Know More</button></Link> */}
                    </div>
                  </Card.Body>
                </Card>
              </div>

              <div >
                <Card>
                  <Card.Body>
                    {/* <div>
                      <img src="../Assets/testimonial4.jpg" alt="" className='testimonial-img1' />
                    </div> */}

                    <div>
                      <div className='name'><b>Dhruva kumar N</b></div>
                      <div className='content-card'>
                        Deputy vice president ( legal)
                        ICICI Lombard GIC Ltd.
                      </div>
                      <div className='content-card'>
                        "I wanted to express my gratitude for the exceptional tutoring you provided. Your patience, clarity in explanations, and ability to make complex concepts understandable have greatly helped improve my daughter understanding of the subject. Thank you for your continuous support and for making learning an enjoyable experience."
                      </div>

                    </div>
                  </Card.Body>
                </Card>
              </div>
              <div >
                <Card>
                  <Card.Body>
                    {/* <div>
                      <img src="../Assets/testimonial4.jpg" alt="" className='testimonial-img1' />
                    </div> */}

                    <div>
                      <div className='name'><b> Prieya  Arun</b></div>
                      <div className='content-card'>
                        Architect
                        Head of the Department
                        Lisaa school of Design.Koramangala
                      </div>
                      <div className='content-card'>
                        "My daughter is in 10th grade and joined Aieden for her tuitions she is studying in ICSE board and it was very difficult for me to find a good coaching centre. When one of my friend told me about Aeiden I went and asked they were very cordial and quite positive in their outcomes and they also mentioned that I was the first enquiry, I got my daughter enrolled for Physics and Math and she just enjoyed her learning process at Aieden .Then she told me that she wants to join for Biology also again it  was a miracle that my daughter started loving Biology ,Most of her unanswered questions are getting cleared now and I am so happy about the centre truly its not a tuition centre but a learning HUB – the child is constantly learning they also keep sending videos and jokes that keeps the child more focussed even while using the phone. Very Professional and a quality time with the most valued teachers at Aeiden. Thank you to all the teachers."
                      </div>

                    </div>
                  </Card.Body>
                </Card>
              </div>

            </OwlCarousel>
          </div>
          {/* </Container> */}
        </div>
      </Container>
    </div>
  )
}

export default Home
