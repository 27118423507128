import React, { useEffect } from 'react'
import '../styles/About.css'
import { Container } from "react-bootstrap";
import Aos from "aos";
import Card from 'react-bootstrap/Card';
import OwlCarousel from 'react-owl-carousel'
import { Link } from 'react-router-dom';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';

const About = () => {


    const options = {

        margin: 20,
        responsiveClass: true,
        nav: false,
        dots: true,
        autoplay: false,
        smartSpeed: 1000,
        responsive: {
    
          0: {
            items: 1,
          },
          400: {
            items: 1,
          },
          600: {
            items: 2,
          },
          700: {
            items: 2,
          },
          1000: {
            items: 3,
          }
    
        },
    
      };
    
    

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })


  return (
    <div className="main-about-us-div">

        <div >
            <img src="/Assets/contactus-banner4.jpg" alt="home img" className='homeheading' />
            <div >
            <p className='abtus-heading'>ABOUT US</p>
            </div>
        </div>


    <Container>
        <div className='row mt-3 mb-3'>

            <div className='col-md-6 mt-3' data-aos="zoom-in" data-aos-duration="3000">
                <p className='fs-3'>At Aedin</p>
                <p>AEDIN, The Learner’s Hub-  dreams take flight and futures are shaped! Our mission is to empower students with the knowledge, skills, and confidence they need to excel in their academic and professional pursuits. we have been committed to providing top-notch education and personalized support to help our students achieve their goals. Our students' success is our top priority, and we take pride in their achievements. 
                    Beyond academics, we believe in holistic development. Our coaching centre fosters a nurturing and inclusive atmosphere where students can grow both personally and professionally. We organize a variety of extracurricular activities, workshops, and seminars to encourage creativity, critical thinking. 
                </p>
            </div>

            <div className='col-md-6' data-aos="zoom-in" data-aos-duration="3000">
                <img src="/Assets/clgstudent.jpg" alt="aboutcontent img" className='abtclg'/>
            </div>

        </div>
    </Container>


    <Container>
        <div className='row mt-3 mb-3'>

        <div className='col-md-6' data-aos="zoom-in" data-aos-duration="3000">
            {/* <img src="/Assets/aedin-students.png" alt="aedin-students" className='ourstd1'/> */}
                <img src="/Assets/students.jpg" alt="aboutcontent img" className='ourstd'/>
            </div>

            <div className='col-md-6 mt-5' data-aos="zoom-in" data-aos-duration="3000">

                <div>
                    <p className='fs-1'>Our Students</p>
                    <p className='fs-3 text-secondary'>Check Out Our Tuition Views</p>
                </div>

                <div>
                    <p className='fs-5 txtt'>Mission</p>
                    <p>"Our mission at Aedin is to empower students with a deep understanding of core concepts in math and science. We believe that true mastery comes from grasping fundamental principles, enabling students to apply their knowledge confidently in any academic or real-world scenario."</p>
                </div>

                <div>
                    <p className='fs-5 txtt'>Vision</p>
                    <p>"To cultivate a generation of empowered learners equipped with deep conceptual understanding and critical thinking skills, ready to excel in academics and beyond."</p>
                </div>

                <div>
                    <p className='fs-5 txtt'>Philosophy</p>
                    <p>"At the heart of our philosophy lies the belief that effective learning stems from clarity and comprehension. Rather than rote memorization, we emphasize conceptual understanding, fostering critical thinking and problem-solving skills among our students. Our approach not only prepares them for exams but equips them with lifelong learning tools."</p>
                </div>

            </div>          

        </div>
    </Container>




    {/* <Container>
    <div className="center-text mt-3 mb-3">Educational Approach:</div>
    
    <div className='row mt-3 mb-3'>

        <div className='col-md-12 mb-4'>
            <div className='main-heading d-flex gap-5 w-100 h-100'>
                <div className='iconsub-heading'>
                    <img src="../Assets/teach1.jpg" alt="teach1" className='class'/>
                </div>
                <div class="card text-center">
                    <div class="card-header">
                    Concept-Based Learning
                    </div>
                    <div class="card-body">
                        <p class="card-text">We prioritize the understanding of underlying principles over mere memorization of facts.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4'>
            <div className='main-heading d-flex gap-5 w-100 h-100'>
                <div className='iconsub-heading'>
                <img src="../Assets/teach2.jpg" alt="teach2" className='class'/>
                </div>
                <div class="card text-center">
                    <div class="card-header">
                    Personalized Attention
                    </div>
                    <div class="card-body">
                        <p class="card-text">Small class sizes ensure individualized attention, allowing each student to progress at their own pace.</p>
                        
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4'>
            <div className='main-heading d-flex gap-5 w-100 h-100'>
                <div className='iconsub-heading'>
                <img src="../Assets/teach3.jpg" alt="teach3" className='class'/>
                </div>
                <div class="card text-center">
                    <div class="card-header">
                    Interactive Teaching
                    </div>
                    <div class="card-body">
                        <p class="card-text">Engaging sessions blend theory with practical examples, encouraging active participation and deeper engagement.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4'>
            <div className='main-heading d-flex gap-5 w-100 h-100'>
                <div className='iconsub-heading'>
                <img src="../Assets/teach4.jpg" alt="teach4" className='class'/>
                </div>
                <div class="card text-center">
                    <div class="card-header">
                    Holistic Development
                    </div>
                    <div class="card-body">
                        <p class="card-text">Beyond academics, we nurture students' holistic growth by fostering curiosity, creativity, and resilience.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</Container> */}


  <Container>
    <h1 className="center-text mt-3 mb-3">Educational Approach:</h1>
    
    <div className='row mt-3 mb-3'>

        <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                <div className='iconsub-heading'>
                    <img src="../Assets/class1.jpg" alt="teach1" className='class'/>
                </div>
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Concept-Based Learning</h4>
                        <p className="card-text">We prioritize the understanding of underlying principles over mere memorization of facts.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                <div className='iconsub-heading'>
                    <img src="../Assets/class2.avif" alt="teach2" className='class'/>
                </div>
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Personalized Attention
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Personalized Attention</h4>
                        <p className="card-text">Small class sizes ensure individualized attention, allowing each student to progress at their own pace.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                <div className='iconsub-heading'>
                    <img src="../Assets/class3.jpg" alt="teach3" className='class'/>
                </div>
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Interactive Teaching
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Interactive Teaching</h4>
                        <p className="card-text">Engaging sessions blend theory with practical examples, encouraging active participation and deeper engagement.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                <div className='iconsub-heading'>
                    <img src="../Assets/class4.jpg" alt="teach4" className='class'/>
                </div>
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Holistic Development
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Holistic Development</h4>
                        <p className="card-text">Beyond academics, we nurture students' holistic growth by fostering curiosity, creativity, and resilience.</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</Container>

{/* <Container>
  <h1 className="center-text mt-3 mb-3">Educational Approach:</h1>
  
  <div className='row mt-3 mb-3'>

      <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
          <div className='main-heading d-flex gap-5'>
              <div className='iconsub-heading'>
                  <img src="../Assets/teach1.jpg" alt="teach1" className='class'/>
              </div>
              <div className="card text-center" style={{ width: '100%' }}>
                  <h5 className="card-header text-warning custom-card-header">
                      Concept-Based Learning
                  </h5>
                  <div className="card-body">
                      <p className="card-text">We prioritize the understanding of underlying principles over mere memorization of facts.</p>
                  </div>
              </div>
          </div>
      </div>

      <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
          <div className='main-heading d-flex gap-5'>
              <div className='iconsub-heading'>
                  <img src="../Assets/teach2.jpg" alt="teach2" className='class'/>
              </div>
              <div className="card text-center" style={{ width: '100%' }}>
                  <h5 className="card-header text-warning custom-card-header">
                      Personalized Attention
                  </h5>
                  <div className="card-body">
                      <p className="card-text">Small class sizes ensure individualized attention, allowing each student to progress at their own pace.</p>
                  </div>
              </div>
          </div>
      </div>

      <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
          <div className='main-heading d-flex gap-5'>
              <div className='iconsub-heading'>
                  <img src="../Assets/teach3.jpg" alt="teach3" className='class'/>
              </div>
              <div className="card text-center" style={{ width: '100%' }}>
                  <h5 className="card-header text-warning custom-card-header">
                      Interactive Teaching
                  </h5>
                  <div className="card-body">
                      <p className="card-text">Engaging sessions blend theory with practical examples, encouraging active participation and deeper engagement.</p>
                  </div>
              </div>
          </div>
      </div>

      <div className='col-md-12 mb-4' data-aos="zoom-in" data-aos-duration="3000">
          <div className='main-heading d-flex gap-5'>
              <div className='iconsub-heading'>
                  <img src="../Assets/teach4.jpg" alt="teach4" className='class'/>
              </div>
              <div className="card text-center" style={{ width: '100%' }}>
                  <h5 className="card-header text-warning custom-card-header">
                      Holistic Development
                  </h5>
                  <div className="card-body">
                      <p className="card-text">Beyond academics, we nurture students' holistic growth by fostering curiosity, creativity, and resilience.</p>
                  </div>
              </div>
          </div>
      </div>

  </div>
</Container> */}


    




<Container>
    <p className='fs-1 text-center'>Co- Founders of Aedin</p>
<div className='row mt-3 mb-5' data-aos="zoom-in" data-aos-duration="3000">

<div className='col-md-12'>
  {/* <Container> */}

  <OwlCarousel className='owl-theme' loop margin={8} {...options}>

    <div class='item'>
      <Card className='testimonial-crd'>
        {/* <Card.Header>
        <img src="../Assets/testimonial1.jpg" alt="" className='testimonial-img'/>
        </Card.Header> */}
        <Card.Body>
          <div>
          <img src="../Assets/shwethaa.PNG" alt="" className='testimonial-img1'/>
          </div>

          <div>
          <div className='name'><b>Shwetha Salian</b></div>
            <div className='content-card'>
            M.Sc B.Ed
            </div>
        <Link to="/ourtean-1"><button className='know-mr'>Know More</button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>


            



    <div class='item'>
      <Card className='testimonial-crd' >
        {/* <Card.Header>
        <img src="../Assets/testimonial2.jpg" alt="" className='testimonial-img'/>
        </Card.Header> */}
        <Card.Body>
          <div>
          <img src="../Assets/rajashree.PNG" alt="" className='testimonial-img'/>
          </div>

          <div>
          <div className='name'><b>Rajasree V.R.</b></div>
            <div className='content-card'>
            M.Sc B.Ed
            </div>
            <Link to="/ourtean-2"><button className='know-mr'>Know More</button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>


    <div class='item'>
      <Card className='testimonial-crd'>
        {/* <Card.Header>
        <img src="" alt="" className='testimonial-img'/>
        </Card.Header> */}
        <Card.Body>
          <div>
          <img src="../Assets/aartishree.jpg" alt="" className='testimonial-img'/>
          </div>

          <div>
          <div className='name'><b>Aartishree T</b></div>
            <div className='content-card'>
            M.Sc B.Ed
            </div>
            <Link to="/ourtean-3"><button className='know-mr'>Know More</button></Link>
          </div>
        </Card.Body>
      </Card>
    </div>


    {/* <div class='item'>
      <Card className='testimonial-crd'>
        <Card.Body>
          <div>
          <img src="../Assets/rinku.jpg" alt="" className='testimonial-img'/>
          </div>

          <div>
          <div className='name'><b>Rinku Kumar </b></div>
            <div className='content-card'>
            M.Sc.  B.Ed.
            </div>
            <Link to="/ourtean-4"><button className='know-mr'>Know More</button></Link>
          </div>
        </Card.Body>
      </Card>
    </div> */}


  </OwlCarousel>
</div>
{/* </Container> */}
</div>
</Container>

    </div>
  )
}

export default About