import React, { useEffect } from 'react'
import { Container} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Aos from "aos";
//import { GoArrowLeft } from "react-icons/go";

const Ourteam = () => {

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })

  return (
    <div>

        
      <Container>
        <div className='row mt-3 mb-3'>
          <div className='col-md-4'>
            <img src="../Assets/shwetha.PNG " alt="" className='ourteams' style={{height:"235px",width:"60%"}} data-aos="zoom-in" data-aos-duration="3000"/>
            <p className='fs-3'><b>Shwetha Salian</b></p>
            <p><b> M.Sc B.Ed</b></p>
          </div>

          <div className='col-md-8'>

           
            {/* <GoArrowLeft style={{height:"100px"}}/> */}
            <Link to="/aboutus"><button className='know-mr text-right'>Go Back</button></Link>
            
            <p className='' style={{ textAlign: "justify", textIndent: "2em" }} data-aos="zoom-in" data-aos-duration="3000">
              A dynamic teacher with a compassionate and sincere approach to imparting knowledge to children of all ages. Seeking to contribute a structured yet creative learning environment. 
              With 16 years of dedicated teaching experience, I am a passionate and innovative science educator committed to nurturing global citizens. Specializing in teaching science to students from grades 1 to 10, I excel in delivering complex scientific concepts in an engaging and comprehensible manner. My expertise lies particularly in instructing students in grades 9 and 10, preparing them for academic success and inspiring a lifelong love for science.
            </p>
          </div>
        </div>
      </Container>

      
      <Container>
    {/* <h1 className="center-text mt-3 mb-3">Educational Approach:</h1> */}
    
    <div className='row mt-3 mb-3'>

        <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body text-justify">
                        <h4 className='headingg'>Professional Experience</h4>
                        <ul>
                        <li className=" text-start list-txt ">Primary teacher in Holy Cross School Bengaluru – 2009 – 2011.</li>
                        <li className=" text-start list-txt">Secondary teacher in MSB Institute Bengaluru- 2011 to 2012.</li>
                        <li className=" text-start list-txt">Teacher in BGS National Public since – 2012 -2024.</li>
                        <li className=" text-start list-txt">Coordinator in BGS National Public School since 2016 -2023.</li>
                        </ul>
{/* <li className="card-text"></li> */}
                    </div>
                </div>
            </div>
        </div>

        


        <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Key Responsibilities</h4>
                        <ul>
                        <li className="text-start list-txt">Develop and implement comprehensive science curricula for grades 1-10.</li>
                        <li className="text-start list-txt">Employ innovative teaching methods to cater to diverse learning styles.</li>
                        <li className="text-start list-txt">Prepare students for higher education with a focus on grades 9 and 10.</li>
                        <li className="text-start list-txt">Foster a classroom environment that encourages curiosity, critical thinking, and problem-solving.</li>
                        <li className="text-start list-txt">Integrate technology and hands-on experiments to enhance learning experiences.</li>
                        <li className="text-start list-txt">Collaborate with colleagues to develop interdisciplinary projects and activities.</li>
                        </ul>
                    </div>

                </div>
             </div>
      </div>




      <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Skills</h4>
                        <ul>
                        <li className="text-start list-txt">Curriculum Development: Expert in designing and updating science curricula that align with educational standards and promote inquiry-based learning.</li>
                        <li className="text-start list-txt">Student Engagement: Proficient in creating interactive lessons that captivate students' interest and encourage active participation.</li>
                        <li className="text-start list-txt">Assessment and Evaluation: Skilled in developing and administering formative and summative assessments to track student progress and provide constructive feedback.</li>
                        <li className="text-start list-txt">Classroom Management: Effective in maintaining a positive and productive classroom environment.</li>
                        <li className="text-start list-txt">Technology Integration: Adept at incorporating digital tools and resources to facilitate interactive and multimedia learning experiences.</li>
                        <li className="text-start list-txt">Communication: Strong ability to communicate complex scientific concepts in a clear and relatable manner.</li>
                        <li className="text-start list-txt">Professional Development: Committed to continuous learning and staying updated with the latest educational practices and scientific advancements.</li>
                        </ul>
                    </div>
                </div>
             </div>
      </div>


            
      <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Achievements</h4>
                        <ul>
                        <li className="text-start list-txt">Successfully increased student pass rates and overall academic performance in science subjects.</li>
                        <li className="text-start list-txt">Recognized for innovative teaching methods and contributions to the school’s science program.</li>
                        <li className="text-start list-txt">Led school-wide science fairs and extracurricular activities promoting STEM education.</li>
                        </ul>
                    </div>
                </div>
             </div>
      </div>



      <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Philosophy</h4>
                        <p>I believe that education is the key to creating global citizens who are not only knowledgeable but also empathetic and responsible. My teaching philosophy revolves around inspiring students to explore the wonders of science while understanding their role in the global community. By fostering critical thinking, creativity, and collaboration, I aim to prepare my students to thrive in an interconnected world.</p>
                    </div>
                </div>
             </div>
      </div>



      <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
            <div className='main-heading d-flex gap-5 '>
                
                <div className="card text-center" style={{ width: '100%' }}>
                    {/* <h5 className="card-header text-warning">
                        Concept-Based Learning
                    </h5> */}
                    <div className="card-body">
                        <h4 className='headingg'>Extracurricular Involvement</h4>
                        <ul>
                        <li className="text-start list-txt">Coordinator for the school's  Science activities.</li>
                        <li className="text-start list-txt">Mentor for students participating in national and international science competitions.</li>
                        <li className="text-start list-txt">Organizer of community science outreach programs and workshops.</li>
                        </ul>
                    </div>
                </div>
             </div>
      </div>



    <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
<div className='main-heading d-flex gap-5 '>
    
    <div className="card text-center" style={{ width: '100%' }}>
        
        <div className="card-body">
            <ul>
            <h4 className='headingg'>Academic Qualification</h4>
            <li className="text-start list-txt">M.Sc - Physics – Sikkim Professional University</li>
            <li className="text-start list-txt">B.Sc – Mathematics, Chemistry and Physics- Canara College, Mangalore University- 2007</li>
            <li className="text-start list-txt">B.Ed- Krishna College , Bengaluru University – 2010</li>
            </ul>
        </div>
    </div>
 </div>
</div>



<Container>
        <div className='row mt-3 mb-3'>
          
          

          <div className='col-md-8 mt-3' data-aos="zoom-in" data-aos-duration="3000">
            
            <p className='fs-3'><b>AWARDS AND RECOGNITION</b></p>
            <ul>
            <li>Won The Best International Mathematics Teacher award by Science Olympiad Foundation in 2016.</li>
            <li>Won The Most Popular Teacher award by BGS NATIONAL PUBLIC SCHOOL in 2016.</li>
            <li>Won The Most Popular Teacher award by BGS NATIONAL PUBLIC SCHOOL in 2018.</li>
            <li>International School Award 2018 has recognised for supporting the integration of International learning in the curriculum.</li>
            <li>Awarded for the best lesson plan.</li>
            </ul>
          </div>
        

            <div className='col-md-4'>
            <img src="../Assets/award.PNG" alt="" className='ourteams' style={{height:"320px",width:"75%"}} data-aos="zoom-in" data-aos-duration="3000"/>
            <p className='fs-3'><b>Contact Information</b></p>
            <p><span><b>Email: </b></span>shwthsalian@gmail.com</p>
            <p><span><b>LinkedIn: </b></span>Shwetha Salian | LinkedIn</p>
          </div>

          </div>
      </Container>






    </div>
</Container>
    </div>
  )
}

export default Ourteam



{/* <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
<div className='main-heading d-flex gap-5 '>
    
    <div className="card text-center" style={{ width: '100%' }}>
        
        <div className="card-body">
            <h4 className='headingg'>Key Responsibilities</h4>
            <li className="card-text"></li>
            <li className="card-text"></li>
            <li className="card-text"></li>
            <li className="card-text"></li>
            <li className="card-text"></li>
            <li className="card-text"></li>
        </div>
    </div>
 </div>
</div>

 */}





















// import React from 'react'
// import { Container } from 'react-bootstrap'

// const Ourteam = () => {
//   return (
//     <div>


//     <Container>
//         <div className='row mt-3 mb-3'>
//             <div className='col-md-4'>
//             <img src="../Assets/testimonial1.jpg" alt="" className='ourteams' style={{height:"300px",width:"100%"}}/>
//             <p>Shwetha Salian</p>
//             <p>Experienced Science Educator</p>
//             </div>
//             <div className='col-md-8'>
//                 <p>A dynamic teacher with a compassionate and sincere approach to imparting knowledge to children of all ages. Seeking to contribute a structured yet creative learning environment. 
//                     With 16 years of dedicated teaching experience, I am a passionate and innovative science educator committed to nurturing global citizens. Specializing in teaching science to students from grades 1 to 10, I excel in delivering complex scientific concepts in an engaging and comprehensible manner. My expertise lies particularly in instructing students in grades 9 and 10, preparing them for academic success and inspiring a lifelong love for science.
//                 </p>
//             </div>
//         </div>
//     </Container>


//     <Container>
//     <div class="card" style="width: 18rem;">
//   <div class="card-body">
//     <h5 class="card-title">Professional Experience</h5>
//     <ul>
//     <li class="card-text">Primary teacher in Holy Cross School Bengaluru – 2009 – 2011.</li>
//     <li class="card-text">Secondary teacher in MSB Institute Bengaluru- 2011 to 2012.</li>
//     <li class="card-text">Teacher in BGS National Public since – 2012 -2024.</li>
//     <li class="card-text">Coordinator in BGS National Public School since 2016 -2023.</li>
//     </ul>
//   </div>
// </div>
//     </Container>

//     </div>
//   )
// }

// export default Ourteam

{/* <li class="card-text"></li> */}