import React from 'react';
import "../styles/ChatWidget.css"
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
// import '../Styles/whatsapp-button.css'; // Create a separate CSS file for styling

const ChatWidget = () => {

  const phoneNumber = 918217261256
  const whatsappMessage = encodeURIComponent("I'm interested to join Aedin.");

  const handleClick = () => {
    window.open(`https://wa.me/${phoneNumber}?text=${whatsappMessage}`, '_blank');
  };

  return (
    <button className="whatsapp-button" onClick={handleClick}>
      <img src="/Assets/whatsapp.png" className="whatsapp-icon" />
    </button>
  );
};

export default ChatWidget;