import React, { useEffect } from 'react'
import '../styles/Gallery.css'
import Carousel from 'react-bootstrap/Carousel';
import { Container } from 'react-bootstrap';
import Aos from 'aos';


function Gallery() {
  useEffect(()=>{
Aos.init({duration:2000});
window.scroll(0,0)
  })

  return (
    <div className='gallery-mainpage'>

<Container>
     <Carousel>
        <Carousel.Item>
          {/* <video controls autoPlay muted loop className="slider-img">
            <source src="../Assets/aedin-video1.mp4" type="video/mp4" />
               </video> */}
          <img src="../Assets/banner-gal1.jpg" alt="" className="slider-img" />
          {/* <Carousel.Caption>
            <div style={{ color: "#fec700" }}>
              <h1 className='carousel-style'>AEDIN</h1>
              <h2 className='carousel-style'>THE LEARNER'S HUB</h2>
            </div>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
         
          <img src="../Assets/banner-gal2.jpg" alt="" className="slider-img" />
          {/* <Carousel.Caption>
            <div style={{ color: "#fec700" }}>
              <h1 className='carousel-style'>AEDIN</h1>
              <h2 className='carousel-style'>THE LEARNER'S HUB</h2>
            </div>
          </Carousel.Caption> */}
        </Carousel.Item>

        <Carousel.Item>
         
           <img src="../Assets/banner-gal3.jpg" alt="" className="slider-img" />
          {/* <Carousel.Caption>
            <div style={{ color: "#fec700" }}>
              <h1 className='carousel-style'>AEDIN</h1>
              <h2 className='carousel-style'>THE LEARNER'S HUB</h2>
            </div>
          </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
  
  </Container>
  
      {/* <Container>
        <div className='row mt-5 mb-5'>
           <div className='col-md-8'>
          <video controls autoPlay muted loop className="video">
            <source src="../Assets/video5.mp4" type="video/mp4" />
           </video>
          </div> 
         

          <div className='col-md-4 mt-5' data-aos="fade-left">
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
              officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
              itaque natus laboriosam quasi quo maxime?
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Sequi incidunt ratione quaerat
              officia eveniet fugiat exercitationem nemo unde cumque nihil autem, debitis eius suscipit
              itaque natus laboriosam quasi quo maxime?
            </p>
          </div>
        </div>
        </Container> */}

        <Container>
        <h1 className='galleryy mt-5 mb-5'>Gallery</h1>

        <div className="row mt-2 mb-4">

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/banner-gal2.jpg" alt="" className='img' />
          </div>


          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/banner-gal1.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3'>           
          <img src="../Assets/gallery4.jpg" alt="" className='img' />
          </div>
          
          </div>

 

        <div className="row mt-2 mb-4">

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary4.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary5.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3'>           
          <img src="../Assets/galary6.jpg" alt="" className='img' />
          </div>

          </div>

          <div className="row mt-2 mb-4">

<div className='col-md-4 mt-3' data-aos="zoom-in">
  <img src="../Assets/galary1.jpg" alt="" className='img' />
</div>

<div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary14.jpg" alt="" className='img' />
          </div>

<div className='col-md-4 mt-3'>           
<img src="../Assets/galary3.jpg" alt="" className='img' />
</div>

</div>

        <div className="row mt-2 mb-4">

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary7.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3'>           
          <img src="../Assets/galary9.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary10.jpg" alt="" className='img' />
          </div>
          </div>
        <div className="row mt-2 mb-4">

        <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary13.jpg" alt="" className='img' />
          </div>


        <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary11.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3'>           
          <img src="../Assets/galary12.jpg" alt="" className='img' />
          </div>

          </div>

        <div className="row mt-2 mb-4">

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary16.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary17.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3'>           
          <img src="../Assets/galary15.jpg" alt="" className='img' />
          </div>


          </div>
        <div className="row mt-2 mb-4">

          
        <div className='col-md-4 mt-3'>           
          <img src="../Assets/galary18.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary19.jpg" alt="" className='img' />
          </div>

          <div className='col-md-4 mt-3' data-aos="zoom-in">
            <img src="../Assets/galary20.jpg" alt="" className='img' />
          </div>


          </div>

          


       <div className="row mt-4 mb-4">

        <div className='col-md-4' data-aos="zoom-in">  
          <video controls autoPlay muted loop className="video">
            <source src="../Assets/aedin-video1.mp4" type="video/mp4" />
           </video>
        </div>

        <div className='col-md-4'>          
            <video controls autoPlay muted loop className="video">
            <source src="../Assets/aedin-video2.mp4" type="video/mp4" />
           </video>
         </div>

          
          <div className='col-md-4' data-aos="zoom-in">          
            <video controls autoPlay muted loop className="video">
            <source src="../Assets/aedin-video3.mp4" type="video/mp4" />
           </video>
          </div>
          

        </div>



       

 
      </Container>

      
     
    </div>
  )
}

export default Gallery