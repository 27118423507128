import React from 'react'
import './App.css';
import Home from './components/Home'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Header from './components/Header'
import About from './components/About'

import Ourteam from './components/Ourteam';
import Ourteam2 from './components/Ourteam2';
import Ourteam3 from './components/Ourteam3';

import Academic from './components/Academic';
import Maths from './components/Maths';
import Science from './components/Science';

import Gallery from './components/Gallery';
import Contact from './components/Contact';


import Footer from './components/Footer';
import Ourteam4 from './components/Ourteam4';

import ChatWidget from "./components/ChatWidget"

const App = () => {
  return (
    <div>
     <Header></Header>

      {/* <Ourteam></Ourteam> 
      < Ourteam2></Ourteam2>
      <Ourteam3></Ourteam3> */}

     {/* <Home></Home> */}
     <BrowserRouter>

     <Routes>

      <Route path="/" element={
        <>         <Home/>
       </>       
       } />


          <Route path="/aboutus" element={
        
            <About/>
        
          } />


           <Route path="/academic" element={<>
            {/* <Maths/>
            <Science/> */}
        {/* <Academic/> */}
        <Maths/>
            
      </>} /> 


          <Route path="/gallery" element={<>
        
         <Gallery/>
       
          </>} />


          <Route path="/contact" element={<>
        <Contact/>
          </>} />


          <Route
            path="/academic/Maths"
            element={<Maths/>}
          ></Route>


          <Route path="/academic/Science" element={<Science/>}
          ></Route>

           <Route path="/ourtean-1" element={<Ourteam/>}
          ></Route>

        <Route path="/ourtean-2" element={<Ourteam2/>}
                  ></Route>


          <Route path="/ourtean-3" element={<Ourteam3/>}
                    ></Route>

          <Route path="/ourtean-4" element={<Ourteam4/>}
                    ></Route>

      </Routes>
      </BrowserRouter>
      <Footer/>
      <ChatWidget />
    </div>
  )
}

export default App
