import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Aos from "aos";

//import Header from '../components/Header';
// import Footerf from '../Footer/Footerf';

const Maths = () => {

    let navigate = useNavigate()
    function Maths(params) {

        navigate("/academic/Maths")

    }
    function Science(params) {

        navigate("/academic/Science")

    }
    
    function Academic(params) {

        navigate("/academic")

    }
    

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })
    
    return (
        <div className='main-servce-common-content-all'>

            {/* <Header /> */}

        <div className="headerServiceMain">
            <img src="/Assets/academic-programs.jpg" alt="" className='academics-img'/>
                <header className="servicenavbar">
                    
                    <div className="bothside bothside1 ">
                        <p className="display-5 academic-heading">ACADEMIC PROGRAMS</p>
                    </div>
                    <div className="bothside bothside2 ">

                     <p className="academic-subheading">  <span>Home</span> / <span><u>Maths</u></span></p> 
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
         </div>
            
            <Container className='d-flex the-main-container-content'>

                <div className='col-md-3  common-all-div-service-content ' >

                    <div className="stickycontroll">
                        <ul className='list-unstyled  ul-main'>

                            <li className="listmain liststyle1 bgcolor  " onClick={Maths}>
                                Maths
                            </li>
                            <li className="listmain liststyle2 " onClick={Science}>
                                Science
                            </li>
                            {/* <li className="listmain liststyle3" onClick={Hospitality}>
                                Hospitality Design
                            </li> */}
                        </ul>
                    </div>


                </div>

           
            <div className='col-md-9 '>
                <div className='fs-2 text-center'>Maths</div>
                <p className='paragraph-service-common-content ' style={{ textAlign: "justify", textIndent: "2em" }}>
                At Aedin We teach math in an easy and simple way, incorporating various tricks to help students grasp concepts quickly and effectively. We closely follow the school curriculum to ensure alignment with what students are learning in their regular classes. Our approach involves providing ample practice with numerous problems, reinforcing their understanding through repetition.
                </p>
                <p className='paragraph-service-common-content ' style={{ textAlign: "justify", textIndent: "2em" }}>
                Once students have a firm grasp of the basic concepts, We gradually introduce them to higher-order thinking skills. This progression ensures that they are not only able to solve standard problems but also develop the ability to tackle more complex and challenging questions. Through this method, We aim to build both competence and confidence in my students, preparing them for academic success.            
                </p>
            </div>





                {/* <div className='col-md-9' >

                    <div className='centercommondiv p-2'>
                        <p className='heading-service-common-content-center fs-2'>Mathematic: Comprehensive Mathematics Curriculum</p>
                        <p className='paragraph-service-common-content'>
                        Covering algebra, geometry, calculus, and more, our math curriculum is designed to build a strong foundation and advanced problem-solving skills. Each subject is structured to progressively deepen your understanding and enhance your analytical abilities, preparing you for academic success and practical applications in various fields. 
                        </p>

                        <p className='heading-service-common-content-center algeocaladd'>Algebra</p>

                         <h4 className='text-secondary'>Foundations of Algebra:</h4>
                            <p className='paragraph-service-common-content'>
                            
                                <ul>
                                <li>Basic Operations: Mastery of addition, subtraction, multiplication, and division of algebraic expressions.</li>
                                <li>Linear Equations and Inequalities: Solving and graphing linear equations and inequalities, understanding slope and intercepts.</li>
                                <li>Polynomials: Operations with polynomials, factoring techniques, and solving polynomial equations.</li>
                                <li>Quadratic Functions: Solving quadratic equations by factoring, completing the square, and the quadratic formula; graphing parabolas.</li>
                                </ul>

                             </p>


                            <h4 className='text-secondary'>Advanced Algebra:</h4>
                             <p className='paragraph-service-common-content'>
                                <ul>
                                    <li>Exponential and Logarithmic Functions: Understanding and applying the properties of exponents and logarithms, solving related equations.</li>
                                    <li>Complex Numbers: Introduction to complex numbers, performing arithmetic operations, and solving equations with complex solutions.</li>
                                    <li>Rational Functions: Analyzing and graphing rational functions, solving rational equations.</li>
                                    <li>Sequences and Series: Exploring arithmetic and geometric sequences and series, using formulas to find terms and sums.</li>
                                </ul>
                             </p>


                 <p className='heading-service-common-content-center algeocaladd'>Geometry</p>

                     <h4 className='text-secondary'>Basic Geometry:</h4>

                        <p className='paragraph-service-common-content'>
                            <ul>
                                <li>Geometric Figures: Identifying and understanding properties of points, lines, angles, and shapes.</li>
                                <li>Triangles and Congruence: Studying different types of triangles, proving congruence, and applying the Pythagorean theorem.</li>
                                <li>Similarity: Understanding and proving similarity in geometric figures, solving problems using similar triangles.</li>
                                <li>Circles: Exploring the properties of circles, including arcs, chords, tangents, and sectors.</li>
                            </ul>
                        </p>

                        <h4 className='text-secondary'>Advanced Geometry:</h4>

                        <p className='paragraph-service-common-content'>
                            <ul>
                                <li>Transformations: Learning about translations, rotations, reflections, and dilations.</li>
                                <li>Coordinate Geometry: Applying algebraic principles to geometric problems, working with distance and midpoint formulas.</li>
                                <li>Area and Volume: Calculating the area of various shapes and the volume of three-dimensional figures.</li>
                                <li>Proofs: Developing logical reasoning skills through geometric proofs and theorems.</li>
                            </ul>
                        </p>


                        <p className='heading-service-common-content-center algeocaladd'>Calculus</p>

                            <h4 className='text-secondary'>Introduction to Calculus:</h4>

                            <p className='paragraph-service-common-content'>
                                <ul>
                                    <li>Limits and Continuity: Understanding the concept of limits, determining the continuity of functions.</li>
                                    <li>Derivatives: Learning the definition and interpretation of the derivative, mastering techniques of differentiation, and applying derivatives to real-world problems.</li>
                                    <li>Integrals: Understanding definite and indefinite integrals, learning techniques of integration, and exploring applications of integrals.</li>
                                    
                                </ul>
                            </p>

                            <h4 className='text-secondary'>Advanced Calculus:</h4>

                            <p className='paragraph-service-common-content'>
                                <ul>
                                    <li>Fundamental Theorem of Calculus: Connecting differentiation and integration, exploring its applications.</li>
                                    <li>Series and Sequences: Studying infinite series, convergence tests, and power series.</li>
                                    <li>Multivariable Calculus: Extending calculus concepts to functions of several variables, partial derivatives, and multiple integrals.</li>
                                    <li>Differential Equations: Introduction to solving basic differential equations and their applications.</li>
                                </ul>
                            </p>


                    <p className='heading-service-common-content-center algeocaladd'>Additional Mathematical Topics</p>

                    <h4 className='text-secondary'>Statistics:</h4>

                    <p className='paragraph-service-common-content'>
                        <ul>
                            <li>Descriptive Statistics: Measures of central tendency, variability, and data visualization.</li>
                            <li>Probability: Basic probability concepts, probability distributions, and expected value.</li>
                            <li>Inferential Statistics: Hypothesis testing, confidence intervals, and statistical significance.</li>
                            <li>Regression and Correlation: Understanding and applying linear regression models, interpreting correlation coefficients.</li>
                        </ul>
                    </p>

                    <h4 className='text-secondary'>Trigonometry:</h4>

                    <p className='paragraph-service-common-content'>
                        <ul>
                            <li>Trigonometric Functions: Understanding sine, cosine, tangent, and their reciprocals; graphing trigonometric functions.</li>
                            <li>Trigonometric Identities and Equations: Proving and applying trigonometric identities, solving trigonometric equations.</li>
                            <li>Applications of Trigonometry: Using trigonometry in real-world applications, including navigation, engineering, and physics.</li>
                            
                        </ul>
                    </p>


                    <p className='paragraph-service-common-content'>
                        Our comprehensive math curriculum is meticulously designed to equip students with the skills and knowledge required to excel in mathematics. Through a blend of foundational concepts and advanced topics, we aim to foster critical thinking, analytical reasoning, and problem-solving proficiency. Whether preparing for higher education or real-world applications, our curriculum ensures students are well-prepared for future challenges.
                    </p>
                       
                        
                    </div>

                </div> */}



                 {/* <Row md={2} className='filper-main-content-main-div'>
                            <Col md={{ offset: 3 }} className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Consultation</p>
                                    </div>
                                    <div className=" fliper flip-box-back">
                                        <p className='p-1'>Understanding your needs, preferences, and budget.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Concept Development</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Creating initial design concepts and mood boards.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Refining the design based on your feedback.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Managing the project to ensure a seamless transformation.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Final Touches</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Adding the finishing details to perfect your space.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row> */}

                {/* <div className='col-md-4 '  >

                    <div className="stickycontroll">
                        <div className='image-common-div-service-content-main' >

                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype1-1.jpg" alt="sad" />
                            </div>
                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype1-2.jpg" alt="sad" />
                            </div>
                        </div>
                    </div>

                </div> */}
    {/* <Footerf /> */}
            </Container>

            
        </div>
    )
}

export default Maths




