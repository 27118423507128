import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { PiPhoneCallFill } from "react-icons/pi";
import { MdEmail } from "react-icons/md";
import { TiWorld } from "react-icons/ti";
import "../styles/Footer.css"; // Import your CSS for footer styles

import { FaFacebook } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="footer-black">
      {/* Yellow color section */}
      <section className="white-bg"></section>

      {/* <Container  className="yellow-color text-center">
        <Row className="justify-content-center">
          <Col md={4} className="mt-4">
            <h2><PiPhoneCallFill style={{fontSize:"40px"}} /> +91 772005006</h2>
          </Col>
          <Col md={4} className="mt-4">
            <h2><MdEmail style={{fontSize:"40px"}} /> youremail@gmail.com</h2>
          </Col>
          <Col md={4} className="mt-4">
            <h2><TiWorld style={{fontSize:"40px"}} /> www.aedintheme.org</h2>
          </Col>
        </Row>
      </Container> */}
      <Container className="yellow-color text-center">
        <Row className="justify-content-center">
          <Col md={4} className="mt-4">
            <div className="icon-text">
              <PiPhoneCallFill style={{ fontSize: "40px" }} />
              <p>+91- 8217261256</p>
            </div>
          </Col>
          <Col md={4} className="mt-4">
            <div className="icon-text">
              <MdEmail style={{ fontSize: "40px" }} />
              {/* <p>info@aedinlearners.in</p> */}
              <a
                href="mailto:info@aedinlearners.in"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <p>info@aedinlearners.in</p>
              </a>
            </div>
          </Col>
          <Col md={4} className="mt-4">
            <div className="icon-text">
              <TiWorld style={{ fontSize: "40px" }} />
              <p>
                <a
                  href="https://aedinlearners.in"
                  target="_blank"
                  style={{ color: "black", textDecoration: "none" }}
                >
                  https://aedinlearners.in
                </a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row className="text-white">
          <Col md={4}>
            <h3>
              <span className="text-yellow">Aedin</span> Education
            </h3>
            <p>
              Established in April 2024 , Aedin Tuition has been instrumental in
              numerous success stories.
            </p>
          </Col>

          <Col md={4}>
            <h3>Address Info</h3>
            <p>
              Eastern Enclave, Near Decathlon, MLA Layout, Kalena Agrahara, BG
              Road, Bengaluru-76
            </p>
          </Col>

          {/* <Col md={4}>
            <h3 style={{marginLeft:"70px"}}>Contact Info</h3>
            <div className='info-txt'>
              <h2 style={{marginRight:"40px"}}><PiPhoneCallFill style={{fontSize:"20px"}} /> +91 772005006</h2>
              <p ><MdEmail style={{fontSize:"20px"}} /> youremail@gmail.com</p>
              <p ><TiWorld style={{fontSize:"20px"}} /> www.aedintheme.org</p>
            </div>
          </Col>
           */}

          <Col md={4}>
            <h3 className="contact-info">Contact Info</h3>

            <div className="info-txt">
              <h2>
                <PiPhoneCallFill style={{ fontSize: "20px" }} />
                +91- 8217261256
              </h2>
              <p>
                <a
                  href="mailto:info@aedinlearners.in"
                  target="_blank"
                  style={{ textDecoration: "none", color: "white" }}
                >
                  <MdEmail style={{ fontSize: "20px" }} /> info@aedinlearners.in
                </a>
              </p>
              <p>
                <a
                  href="https://aedinlearners.in"
                  target="_blank"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <TiWorld style={{ fontSize: "20px" }} />{" "}
                  https://aedinlearners.in
                </a>
              </p>
            </div>
          </Col>
        </Row>

        <div className="row mt-3 mb-3 ">
          <div className="col-md-16 ">
            <div className="d-flex justify-content-center align-items-center gap-4 ">
              <p className="follow-text  text-center fs-3 mb-4">
                <b>Follow Us</b>
              </p>

              <a href="https://www.facebook.com/profile.php?id=61556843654247&mibextid=ZbWKwL">
                <FaFacebook className="fs-3 mb-4" />
              </a>
              <a href="https://www.instagram.com/aedin680?igsh=MTd1dnN5Y2s4NnQ0Mg==">
                <FaSquareInstagram className="fs-3 mb-4" />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
