import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Aos from "aos";
import { Link } from 'react-router-dom';

const Ourteam2 = () => {

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0)
  })

  return (
    <div>

<Container>
        <div className='row mt-3 mb-3'>
          <div className='col-md-6 mt-5'>
            <img src="../Assets/award1.PNG" alt="" className='ourteams' style={{height:"235px",width:"60%"}} data-aos="zoom-in" data-aos-duration="3000"/>
            <p className='fs-3'><b>Rajasree V.R.</b></p>
            <p> M.Sc B.Ed</p>
          </div>

          <div className='col-md-6'>
          <Link to="/aboutus"><button className='know-mr text-right'>Go Back</button></Link>
            <p className='' style={{ textAlign: "justify", textIndent: "2em" }}>
            I am a Science lover with 20 years of experience in education, I have dedicated myself to fostering a dynamic learning environment where students can thrive academically and personally. I believe in inquiry-based education and my goal is to inspire curiosity in young minds and provide them a learning environment in such a way that they evolve themselves in to independent learners. Throughout my career, I have had the privilege of teaching Grade 1 to MSc students in various prestigious institutions such as St.Xavior’s High School, Raipur, BGS NPS Bangalore, Sanjeevani College Kerala.
            In my classroom, you can expect innovative teaching methods using easily available teaching aids which simulate the subject content, project-based learning and methods to become a smart learner.

            </p>
          </div>
        </div>
      </Container>


<Container>
<div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
<div className='main-heading d-flex gap-5 '>
    
    <div className="card text-center" style={{ width: '100%' }}>
        
        <div >
            <h4 className='headingg mt-2'>EDUCATIONAL QUALIFICATION</h4>
            <ul>
            <li className=" text-start list-txt">Msc Biotechnology from Cochin University of Science and Technology, Kerala.</li>
            <li className="text-start list-txt">BEd Natural Science, Mahathma Gandhi University, Kerala.</li>
            <li className=" text-start list-txt">PGDCA Bioinformatics, Bioinformatics institute of India.</li>
            <li className=" text-start list-txt">Environment Management 2023 from IISc, Bangalore. </li>
            </ul>
        </div>
    </div>
 </div>
</div>

 


  <div className='col-md-12 mb-4 ' data-aos="zoom-in" data-aos-duration="3000">
<div className='main-heading d-flex gap-5 '>
    
    <div className="card text-center" style={{ width: '100%' }}>
        
        <div className="card-body">
            <h4 className='headingg mt-2'>ACHIEVEMENTS</h4>
            <ul>
            <li className="text-start list-txtt ">Received Global Teacher Accreditation Award in 2013 Based on Action research -
Awarded by British council.
</li>
            <li className="text-start list-txtt">Visited Rawlins Academy, Rawlins Academy, United Kingdom as a part of connecting class room program in 2014.</li>
            <li className="text-start list-txtt">Received Tetra pack Best TEACHER Award of Bangalore Region in 2015 for sensitizing environment awareness in young minds in their respective Schools from TERI.</li>
            <li className="text-start list-txtt">Secured 2nd Rank in CENTA Teacher’s Professional Olympiad in 2018.</li>
            <li className="text-start list-txtt">Received SHIKSHAK SAMMAN- National School Teachers award 2018 from CED Foundation for the contribution in the field of education. </li>
            </ul>
        </div>
    </div>
 </div>
</div>

 
</Container>

    </div>
  )
}

export default Ourteam2