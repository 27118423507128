import React, { useEffect } from 'react'
import { Container } from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useNavigate } from 'react-router-dom';
import Aos from "aos";
//import Header from '../components/Header';

// import Footerf from '../Footer/Footerf';

const Science = () => {

    let navigate = useNavigate()

    function Maths(params) {

        navigate("/academic/Maths")

    }
    function Science(params) {

        navigate("/academic/Science")

    }
    // function Hospitality(params) {

    //     navigate("/service/Hospitality")

    // }

    function Academic(params) {

        navigate("/academic")

    }

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })

    return (
        <div className='main-servce-common-content-all'>

            {/* <Header /> */}

            {/* <div className="headerServiceMain">

                <header className="servicenavbar">
                    <div className="bothside bothside1 ">
                        <p className="display-5 fs-1">ACADEMIC PROGRAMS</p>
                    </div>
                    <div className="bothside bothside2">
                        <span>Home</span> / <span><u>Science</u></span>
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
            </div> */}


<div className="headerServiceMain">
            <img src="/Assets/academic-programs.jpg" alt="" className='academics-img'/>
                <header className="servicenavbar">
                    
                    <div className="bothside bothside1 ">
                        <p className="display-5 academic-heading">ACADEMIC PROGRAMS</p>
                    </div>
                    <div className="bothside bothside2 ">

                     <p className="academic-subheading">  <span>Home</span> / <span><u>Science</u></span></p> 
                    </div>
                </header>
                <figure className="figuretagService">
                    <img src="/Assets/Servicenavbar.jpg" alt="" />
                </figure>
         </div>

            <Container className='d-flex the-main-container-content'>

                <div className='col-md-3  common-all-div-service-content ' >

                    <div className="stickycontroll">
                        <ul className='list-unstyled  ul-main'>

                            <li className="listmain liststyle1" onClick={Maths}>
                                Maths
                            </li>
                            <li className="listmain liststyle2  bgcolor " onClick={Science}>
                                Science
                            </li>
                            {/* <li className="listmain liststyle3" onClick={Hospitality}>
                                Hospitality Design
                            </li> */}
                        </ul>
                    </div>
                </div>



                
                <div className='col-md-9'>
                <div className='fs-2 text-center'>Science</div>
                    <p className='paragraph-service-common-content ' style={{ textAlign: "justify", textIndent: "2em" }}>
                    At Aedin, we teach science in a unique and engaging way. We introduce concepts by asking for various examples, making the learning process interactive and relatable. Our approach to teaching science is fun and hands-on, incorporating numerous experiments to bring theories to life.
                    </p>
                    <p className='paragraph-service-common-content ' style={{ textAlign: "justify", textIndent: "2em" }}>
                    We use digital tools to enhance clarity and understanding of complex concepts. Our lessons are filled with activities designed to ensure students grasp the material thoroughly. Additionally, we provide ample practice and pose various application questions to reinforce learning and promote critical thinking. Through this dynamic and comprehensive method, we aim to make science both enjoyable and educational for our students.
                    </p>
                </div>



                {/* <div className='col-md-9' >

                    <div className='centercommondiv p-2'>
                        <p className='heading-service-common-content-center fs-2'>Science:Comprehensive Science Curriculum</p>
                        <p className='paragraph-service-common-content'>
                        From physics and chemistry to biology, we explore the wonders of science through hands-on experiments and theoretical exploration. Our curriculum is designed to ignite curiosity, foster a deep understanding of scientific principles, and develop critical thinking and problem-solving skills.
                        </p>



 <p className='heading-service-common-content-center pkbh'>Physics</p>

<h4 className='text-secondary'>Foundations of Physics:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Mechanics: Study of motion, forces, energy, and momentum. Key topics include Newton's laws of motion, work and energy, and the principles of conservation.</li>
           <li>Thermodynamics: Understanding heat, temperature, and energy transfer. Exploring the laws of thermodynamics and their applications.</li>
           <li>Waves and Optics: Examining the properties of waves, sound, and light. Understanding reflection, refraction, and optical instruments.</li>
           <li>Electricity and Magnetism: Exploring electric fields, circuits, magnetism, and electromagnetic induction. Understanding the relationship between electricity and magnetism</li>
       </ul>
   </p>

   <h4 className='text-secondary'>Advanced Physics:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Modern Physics: Introduction to quantum mechanics, atomic and nuclear physics, and relativity. Exploring the fundamental particles and forces of the universe.</li>
           <li>Fluid Dynamics: Study of the behavior of fluids in motion and at rest. Understanding concepts such as buoyancy, pressure, and viscosity.</li>
           <li>Astrophysics: Exploring the principles governing the universe, including the life cycle of stars, galaxies, and cosmology.</li>
          
       </ul>
   </p> 


   <p className='heading-service-common-content-center pkbh'>Chemistry</p>

<h4 className='text-secondary'>Foundations of Chemistry:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Matter and Its Properties: Understanding the states of matter, atomic structure, and the periodic table. Exploring chemical bonds and molecular structure.</li>
           <li>Chemical Reactions: Study of types of chemical reactions, stoichiometry, and the principles of conservation of mass and energy.</li>
           <li>Thermochemistry: Understanding energy changes in chemical reactions, enthalpy, and calorimetry.</li>
           <li>Chemical Kinetics and Equilibrium: Exploring reaction rates, activation energy, and dynamic equilibrium.</li>
       </ul>
   </p>

   <h4 className='text-secondary'>Advanced Chemistry:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Organic Chemistry: Study of carbon-based compounds, including hydrocarbons, functional groups, and biomolecules.</li>
           <li>Inorganic Chemistry: Exploring the properties and reactions of inorganic compounds, coordination chemistry, and transition metals.</li>
           <li>Analytical Chemistry: Techniques and methods for analyzing substances, including spectroscopy, chromatography, and titration.</li>
           <li>Environmental Chemistry: Understanding the chemical processes occurring in the environment, pollution, and sustainable practices.</li>
       </ul>
   </p>


   <p className='heading-service-common-content-center pkbh'>Biology</p>

<h4 className='text-secondary'>Foundations of Biology:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Cell Biology: Study of cell structure, function, and processes such as mitosis and meiosis. Understanding cellular respiration and photosynthesis.</li>
           <li>Genetics: Exploring the principles of heredity, DNA structure and function, and genetic variation.</li>
           <li>Evolution: Understanding the theory of evolution, natural selection, and the history of life on Earth.</li>
           <li>Ecology: Study of ecosystems, biodiversity, and the interactions between organisms and their environment.</li>
       </ul>
   </p>

   <h4 className='text-secondary'>Advanced Biology:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Human Anatomy and Physiology: Detailed study of the human body systems, including the circulatory, respiratory, and nervous systems.</li>
           <li>Microbiology: Exploring the world of microorganisms, including bacteria, viruses, fungi, and their roles in health and disease.</li>
           <li>Biotechnology: Understanding the applications of biotechnology in medicine, agriculture, and industry.</li>
           <li>Environmental Science: Study of environmental issues, conservation, and the impact of human activities on the natural world.</li>
       </ul>
   </p>



   <p className='heading-service-common-content-center pkbh'>Hands-On Experiments and Theoretical Exploration</p>
   <p className='paragraph-service-common-content'>
   Our science curriculum emphasizes the importance of both hands-on experiments and theoretical exploration to provide a well-rounded understanding of scientific principles:
    </p>


<h4 className='text-secondary'>Hands-On Experiments:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Laboratory Work: Conducting experiments to observe and analyze scientific phenomena. Emphasizing safety and proper lab techniques.</li>
           <li>Field Studies: Engaging in real-world investigations to study ecosystems, collect data, and apply scientific methods.</li>
           <li>Project-Based Learning: Developing and executing science projects that encourage creativity, critical thinking, and collaboration.</li>
           
       </ul>
   </p>

   <h4 className='text-secondary'>Theoretical Exploration:</h4>

   <p className='paragraph-service-common-content'>
       <ul>
           <li>Conceptual Understanding: Building a strong theoretical foundation through lectures, discussions, and problem-solving exercises.</li>
           <li>Scientific Inquiry: Encouraging students to ask questions, form hypotheses, and design experiments to test their ideas.</li>
           <li>Interdisciplinary Approach: Connecting concepts from different branches of science to provide a comprehensive understanding of the natural world.</li>
          
       </ul>
   </p>


   <p className='paragraph-service-common-content'>
    Our comprehensive science curriculum is designed to inspire a passion for discovery and equip students with the skills and knowledge needed to excel in scientific endeavors. By integrating hands-on experiments with theoretical exploration, we aim to cultivate a deep appreciation for the wonders of science and prepare students for future scientific challenges and opportunities.       
    </p>

     </div>

                </div> */}



             {/* <Row md={2} className='filper-main-content-main-div'>
                            <Col md={{ offset: 3 }} className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Consultation</p>
                                    </div>
                                    <div className=" fliper flip-box-back">
                                        <p className='p-1'>Understanding your needs, preferences, and budget.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Concept Development</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Creating initial design concepts and layouts.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Design Refinement</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Fine-tuning the design based on feedback.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Implementation</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Overseeing the transformation of your space.</p>
                                    </div>
                                </div>

                            </Col>
                            <Col className="flip-box">

                                <div className='flip-box-inner'>
                                    <div className=" fliper flip-box-front">
                                        <p>Final Touches</p>
                                    </div>
                                    <div className="fliper flip-box-back">
                                        <p className='p-1' >Adding the finishing touches to ensure every detail is perfect.</p>
                                    </div>
                                </div>

                            </Col>
                        </Row>
 */}



                {/* <div className='col-md-4 '  >

                    <div className="stickycontroll">
                        <div className='image-common-div-service-content-main' >

                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype2-1.jpg" alt="" />
                            </div>
                            <div className='image-common-div-service-content'>
                                <img src="/Assets/servicetype2-2.jpg" alt="" />
                            </div>

                        </div>

                    </div>

                 </div> */}



            </Container>


            {/* <Footerf /> */}

        </div>
    )
}

export default Science