import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Aos from "aos";
import { Link } from 'react-router-dom';

const Ourteam4 = () => {

    useEffect(() => {
        Aos.init();
        window.scroll(0, 0)
      })

  return (
    <div>

<Container>
        <div className='row mt-3 mb-3'>
          <div className='col-md-4' data-aos="zoom-in" data-aos-duration="3000">
            <img src="../Assets/rinku.jpg" alt="" className='ourteams' style={{height:"300px",width:"100%"}} data-aos="zoom-in" data-aos-duration="3000"/>
            <p className='fs-3'><b>Rinku Kumar</b></p>
            <p>M.Sc. 
            B.Ed.</p>
          </div>

          <div className='col-md-8' data-aos="zoom-in" data-aos-duration="3000">
          <Link to="/aboutus"><button className='know-mr text-right'>Go Back</button></Link>
          <h4 className=' mt-2'>Experience & Awards</h4>
          <li>CTET qualified.</li>
          <li>11 years of teaching experience.</li>
         
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Ourteam4
