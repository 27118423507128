import React from 'react';
import { Container, Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { HiOutlineHome } from 'react-icons/hi';
import { AiTwotoneMail } from 'react-icons/ai';
import '../styles/Header.css';
import { FaSquareWhatsapp } from "react-icons/fa6";
import { FaWhatsappSquare } from "react-icons/fa";

const Header = () => {
  return (
    <div>

    <Container>
    
      <div className='row'>

        <div className='col-md-12 header-contents' data-aos="zoom-in" data-aos-duration="3000">

          <img src="../Assets/logo1.jpg" alt="aedin logo" className='aedinlogo'/>
          <div className='free-democls fs-2'><b>Free Demo Class</b></div>
          <FaWhatsappSquare className='fawhatsup'/>
      <div>
         <p><b>+91- 8217261256</b></p>  
         <p><b>+91- 9742036366</b></p> 
     </div>

     <div>
         <p><b>+91- 9902268073</b></p> 
         <p><b>+91- 8660202361</b></p>
     </div>     
          
                    
        </div>

      </div>
    </Container>

    {/* <div>
      <img src="" alt="" className='adinn-logoo'/>
      <FaSquareWhatsapp className='fs-1'/>
    </div> */}

{/* <div className="container px-0">
    <div className='row no-gutters'>
        <div className='col-md-3' data-aos="zoom-in" data-aos-duration="3000">
            <div>
                <img src="../Assets/aedinlogo.jpg" alt="aedin logo" className='aedinlogo'/>
            </div>
        </div>

        <div className='col-md-9' data-aos="zoom-in" data-aos-duration="3000">
            <div className='contact-info border p-3'>
                <div className='free-demos'>
                    <div className='free-democls'><b>Free Demo Class</b></div>
                    <div>
                        <FaSquareWhatsapp className='fs-1 whatsup'/>
                    </div>
                </div>
                <div className='mbl-numbers mt-3'>
                    <div className='phone-numbers'>
                        <div><b>+91- 8217261256</b></div>
                        <div><b>+91- 9742036366</b></div>
                    </div>
                    <div className='phone-numbers'>
                        <div><b>+91- 9902268073</b></div>
                        <div><b>+91- 8660202361</b></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> */}

     {/* first navbar */}
{/* 
     <div className="container px-0">

        <div className='row no-gutters'>

            <div className='col-md-3 ' data-aos="zoom-in" data-aos-duration="3000">
                <div>
                    <img src="../Assets/aedinlogo.PNG" alt="aedin logo" className='aedinlogo'/>
                </div>
            </div>

 
            <div className='free-demo  col-md-4' data-aos="zoom-in" data-aos-duration="3000">
                <div className='free-demos  '>
                    <div className='free-democls fs-2'><b>Free Demo Class</b></div>
                    
                    <FaWhatsappSquare />
                    
                </div>
            </div>

       
            
          <div className='col-md-5 ' data-aos="zoom-in" data-aos-duration="3000">

            <div className=' mt-3 mr-5 p-2  d-flex gap-5 align-items-center'>
              <div>
                <div >
                <b>+91- 8217261256</b> 
                </div>

                <div>
                 <b>+91- 9742036366</b>
                </div>

              </div>

              <div>
                <div>
                 <b>+91- 9902268073</b>
                </div>

                <div>
                 <b>+91- 8660202361</b>
                </div>
                 
              </div>

            </div>

          </div>
      </div>
        
    </div>  */}
    
{/* 
          <div className='col-md-3 '>

            <div className=' mt-3 mr-5 p-2  '>
                <div >
                <b>+91- 8217261256</b> 
                </div>
                
                <div>
                <b>+91- 9742036366</b>
                </div>
            </div>
            </div> */}
            
       
 

    {/* Navbar */}
 
        <div className="headers">
        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            style={{
              padding: "0px",
              background: "#271300",
              position: "sticky",
              top: "0px",
              zIndex: "999",
              backgroundColor: "rgb(9,122,215)",
              
            }}
          >


            <Container fluid style={{ padding: "0px",height:"40px" }}>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
                className="navbar-dark"
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton />
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-end flex-grow-1 pe-3"
                    style={{ alignItems: "center" }}
                  >
                    <Nav.Link href="/" className="tail-text" >
                      HOME
                    </Nav.Link>
                    <Nav.Link href="/aboutus" className="tail-text" >
                      ABOUT US
                    </Nav.Link>
                    <Nav.Link href="/academic" className="tail-text" >
                      ACADEMIC PROGRAMS
                    </Nav.Link>
                    <Nav.Link href="/gallery" className="tail-text" >
                      GALLERY
                    </Nav.Link>
                    <Nav.Link href="/contact" className="tail-text">
                      CONTACT US
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>

                
              </Navbar.Offcanvas>
            </Container>



          </Navbar>

        ))}
      </div>  

  {/* ///////////////////////////////////////// */}

{/* <div
        className="headers"
        style={{
          position: "sticky",
          top: "0px",
           zIndex: "999",
          backgroundColor: "green",
        }}
      >
        {["xl"].map((expand) => (
          <Navbar
            key={expand}
            expand={expand}
            style={{ padding: "0px", background: "rgb(0,117,218)" }}
          >
            <Container fluid style={{ padding: "5px 20px" }}>
              <div className="d-flex gap-2">
               
              </div>
              <Navbar.Toggle
                aria-controls={`offcanvasNavbar-expand-${expand}`}
              />
              <Navbar.Offcanvas
                id={`offcanvasNavbar-expand-${expand}`}
                aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                placement="end"
              >
                <Offcanvas.Header closeButton />
                <Offcanvas.Body>
                  <Nav
                    className="justify-content-end flex-grow-1 pe-3"
                    style={{ alignItems: "center" }}
                  >
                    <Nav.Link href="/" className="tail-text" style={{color:"white", fontSize:"18px"}}>
                      HOME
                    </Nav.Link>
                    <Nav.Link href="/aboutus" className="tail-text" style={{color:"white",fontSize:"18px"}}>
                      ABOUT US
                    </Nav.Link>
                    <Nav.Link href="/academic" className="tail-text" style={{color:"white",fontSize:"18px"}}>
                    ACADEMIC DETAILS
                    </Nav.Link>

                    <Nav.Link href="/gallery" className="tail-text" style={{color:"white",fontSize:"18px"}}>
                      GALLERY
                    </Nav.Link>
                    <Nav.Link href="/contact" className="tail-text" style={{color:"white",fontSize:"18px"}}>
                      CONTACT US
                    </Nav.Link>
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        ))}
        
      </div> */}


    </div>
  );
};

export default Header;



