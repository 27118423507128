import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import Aos from "aos";
import { Link } from 'react-router-dom';

const Ourteam3 = () => {

  useEffect(() => {
    Aos.init();
    window.scroll(0, 0)
  })

  return (
    <div>

<Container>
        <div className='row mt-3 mb-3'>
          <div className='col-md-4' data-aos="zoom-in" data-aos-duration="3000">
            <img src="../Assets/aartishree.jpg" alt="" className='ourteams' style={{height:"235px",width:"60%"}} data-aos="zoom-in" data-aos-duration="3000"/>
            <p className='fs-3'><b>Aartishree T</b></p>
            <p> M.Sc B.Ed</p>
          </div>

          <div className='col-md-8' data-aos="zoom-in" data-aos-duration="3000">
          <Link to="/aboutus"><button className='know-mr text-right'>Go Back</button></Link>
          <h4 className=' mt-2'>Experience & Awards</h4>
          <li>20 years of teaching experience. </li>
          <li>Worked as TGT in BGS National Public School Bangalore for 12 years. </li>
          <li>Served as an Examiner in CBSE board evaluation for class 10. Secured third prize at ISRO (U R Rao Satellite center) in teachers’ event NSD 2024. </li>
          </div>
        </div>
      </Container>

    </div>
  )
}

export default Ourteam3